import { useQuery } from '@apollo/client';
import { MY_DOCUMENTS } from '../graphql';

const useInvoicesIds = (invoicesIds, queryDocument = MY_DOCUMENTS) => {
  const { data, ...extraQuery } = useQuery(
    queryDocument,
    {
      variables: {
        id_In: invoicesIds,
        first: 100,
      },
      skip: invoicesIds.length === 0,
    },
  );

  return {
    invoices: data?.invoices?.edges?.map((edge) => edge.node) || [],
    ...extraQuery,
  };
};

export default useInvoicesIds;
