import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from '../graphql';

const useGetCountries = (onCompleted = () => {}) => {
  const { data, refetch } = useQuery(GET_COUNTRIES, {
    onCompleted,
    onError: () => setTimeout(refetch, 1500),
  });
  return data?.getCountries.map((obj) => ({ ...obj, label: obj.name }));
};

const useCountriesNameArray = () => {
  const countries = useGetCountries();
  const countryArray = [];
  countries?.getCountries?.forEach((country) => {
    countryArray.push(country.name);
  });
  return countryArray;
};

export { useCountriesNameArray, useGetCountries };
