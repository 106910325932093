import { useQuery } from '@apollo/client';
import { GET_BANK_ACCOUNTS } from '../graphql';

const useBankAccounts = (companyIds) => {
  const { data, ...extraQuery } = useQuery(GET_BANK_ACCOUNTS, { variables: { companyIds } });

  return {
    accounts: data?.getCompanyBankAccounts?.filter?.((account) => account.active) || [],
    ...extraQuery,
  };
};

export default useBankAccounts;
