import { saveAs } from 'file-saver';
import { useLazyQuery } from '@apollo/client';
import { GET_EXCEL_TEMPLATE_QUERY } from '../graphql/misc/queries';

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // eslint-disable-next-line no-undef
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export default (fileName = 'plantilla') => useLazyQuery(GET_EXCEL_TEMPLATE_QUERY, {
  onCompleted: (data) => {
    if (data?.getExcelTemplate) {
      // Convert the base64 data to a Blob
      const base64Data = data.getExcelTemplate;
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = b64toBlob(base64Data, contentType);

      // Save the Blob as a file using the file-saver library
      saveAs(blob, `${fileName}.xlsx`);
    }
  },
});
