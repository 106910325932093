import { useQuery } from '@apollo/client';
import { GET_COMMERCIAL_EXECUTIVES_INFORMATION } from '../graphql';

const useGetExecutives = (filters) => {
  const { data, loading, ...extraQuery } = useQuery(
    GET_COMMERCIAL_EXECUTIVES_INFORMATION,
    { variables:
      {
        orderBy: 'user_FirstName',
        ...filters,
      },
    },
  );
  return {
    loading,
    executives: data?.getExecutiveAssignmentInformation || [],
    data,
    ...extraQuery,
  };
};

export default useGetExecutives;
