import { useCallback, useState } from 'react';

const useFileState = () => {
  const [file, setFile] = useState();
  const handleEvent = useCallback((event) => {
    const { files } = event.target;
    setFile(files[0]);
  }, []);
  const clean = useCallback(() => setFile(null));
  return [file, handleEvent, clean];
};

export default useFileState;
