import { useState, useEffect, useCallback, useRef } from 'react';

const useMidPosition = (ref) => {
  const [midPosition, setMidPosition] = useState(null);
  const setDefaultHeight = useCallback((_el) => {
    setMidPosition(_el[0].target.offsetTop + _el[0].target.offsetHeight / 2);
  }, []);
  // eslint-disable-next-line no-undef
  const observer = useRef(new ResizeObserver(setDefaultHeight));
  useEffect(() => {
    const element = ref.current;
    if (element) {
      const { current } = observer;
      current.observe(element);
      return () => current.unobserve(element);
    }
    return () => {};
  }, [ref, setDefaultHeight]);
  return midPosition;
};

export default useMidPosition;
