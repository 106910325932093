import { useQuery } from '@apollo/client';
import { MY_OFFERS } from '../graphql';

const useOffers = (queryOptions) => {
  const { data, ...extraQuery } = useQuery(
    MY_OFFERS,
    queryOptions,
  );

  return {
    offers: data?.invoices?.edges?.map((edge) => edge.node) || [],
    ...extraQuery,
    totalCount: data?.invoices?.totalCount || 0,
  };
};

export default useOffers;
