import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { GET_BACK_OFFICE_USER } from '../graphql/user';
import { refreshTokenVar } from '../apollo/reactive-variables/auth';

const useGetBackOfficeUser = (onCompleted = () => {}) => {
  const isLogged = useReactiveVar(refreshTokenVar);

  const { data, loading, refetch, error } = useQuery(GET_BACK_OFFICE_USER, {
    onCompleted,
    onError: () => setTimeout(refetch, 1500),
  });

  useEffect(() => {
    if (!error && isLogged && !loading && !data?.getUser) {
      refetch();
    }
  }, [loading, isLogged, data]);

  return data?.getUser;
};

export default useGetBackOfficeUser;
