import { useState } from 'react';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

const useEnum = (enumQuery, defaultOption = {}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const { data } = useQuery(enumQuery);
  const options = data ? [...Object.values(data)[0]] : [];
  if (!isEmpty(defaultOption)) {
    options.unshift(defaultOption);
  }
  return [options, selectedOption, setSelectedOption];
};

export default useEnum;
